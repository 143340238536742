import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import moment from 'moment';
import { FileUploader } from "react-drag-drop-files";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

function Blog() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    const [postList, setPostList] = useState([]);
    const getPostList = () => {
        axios.get(`https://api.vtalentsolution.com/api/getPosts`)
        .then((res) => {
            setPostList(res.data);
        });
    }
    const switchPost = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đổi trạng thái bài viết?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchPost?postId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(()=>{
                            getPostList();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [file, setFile] = useState(null);
    const [showImg, setShowImg] = useState('');
    const fileTypes = [
        'gif', 'jpeg', 'jpg', 'png', 'svg', 'jfif', 'tfif', 
        'tif', 'blob', 'webpimage', 'webp', 'raw', 'GIF', 
        'JPEG', 'JPG', 'PNG', 'SVG', 'JFIF', 'TFIF', 'TIF', 
        'BLOB', 'WEBPIMAGE', 'WEBP', 'RAW'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowImg(url);
    } 
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createPost = () => {
        setShow1(true);
    }
    const submitCreatePost = () => {
        if(title === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tiêu đề bài viết",
            });
        }else if (content === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập nội dung bài viết",
            });
        } else if (file === null){
            Toast.fire({
                icon: "warning",
                title: "Chưa chọn ảnh tiêu đề cho bài viết",
            });
        }else {
            const data = new FormData();
            data.append('title', title);
            data.append('content', content);
            data.append('image', file);
            axios({
                method: 'post',
                url: `https://api.vtalentsolution.com/api/createPost`,
                data: data
            })
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã tạo bài viết thành công",
                    }).then(() => {
                        setShow1(false);
                        setContent('');
                        setTitle('');
                        getPostList();
                    });
                } else if (res.data.msg.title) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.title,
                    });
                } else if (res.data.msg.content) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.content,
                    });
                } else if (res.data.msg) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editPost = (i, t, c) => {
        setShow2(true);
        setId(i);
        setTitle(t);
        setContent(c);
    }
    const submitEditPost = async () => {
        if(title === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tiêu đề bài viết",
            });
        }else if (content === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập nội dung bài viết",
            });
        } else {
            const data = new URLSearchParams();
            data.append('postId', id);
            data.append('title', title);
            data.append('content', content);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editPost",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã sửa thành công",
                    }).then(() => {
                        setShow2(false);
                        setContent('');
                        setTitle('');
                        getPostList();
                    });
                } else if (result.msg.postId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.postId,
                    });
                } else if (result.msg.title) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.title,
                    });
                } else if (result.msg.content) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.content,
                    });
                }
            })
        }
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const changeImageTitle = (i, image) => {
        setShow3(true);
        setId(i);
        setShowImg(`https://api.vtalentsolution.com/images/`+image+``)
    }
    const submitChangeImageTitle = async () => {
        if(file === null){
            Toast.fire({
                icon: "warning",
                title: "Chưa chọn ảnh tiêu đề cho bài viết",
            });
        }else {
            const data = new FormData();
            data.append('postId', id);
            data.append('image', file);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/changeImageTitle",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã thay đổi thành công",
                    }).then(() => {
                        setShow3(false);
                        getPostList();
                    });
                } else if (result.msg.postId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.postId,
                    });
                } else if (result.msg) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg,
                    });
                }
            })
        }
    }
    const delPost = async (i) => {
        Swal.fire({
            title: "Bạn có muốn xóa bài viết này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("postId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deletePost",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            getPostList();
                        });
                    } else if (result.msg.postId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.postId,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [searchPosts, setSearchPosts] = useState([]);
    const getPostsSearch = async (e) => {
        await axios.get(`https://api.vtalentsolution.com/api/searchPosts?search=`+e+``)
        .then((res)=>{
            setSearchPosts(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getPostList()
        }else{
            window.location.replace('/');
        }  
    }, []);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Danh sách Bài viết</h5>
                                        <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createPost}>
                                            <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                            Thêm
                                        </a>
                                    </div>
                                    <div className="row mb-4">
                                        {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                        <div className="col-lg-6 col-12">
                                            <input type="text" className="form-control border small" placeholder="Search for..." 
                                            aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getPostsSearch(e.target.value)}/>
                                        </div>
                                        {
                                            searchPosts.length>0 &&
                                            <div className="col-12 mt-4">
                                                <h6>Kết quả tìm kiếm: {searchPosts.length}</h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    postList && postList.length > 0 && searchPosts.length <= 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Ảnh tiêu đề</th>
                                                    <th>Tiêu đề</th>
                                                    <th>Tình trạng</th>
                                                    <th>Ngày tạo</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                postList.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'  style={{ cursor: "pointer" }} onClick={()=>changeImageTitle(item.id, item.imageTitle)}>
                                                        <img src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} style={{height: '50px'}}/>
                                                    </td>
                                                    <td className='align-middle'>{item.title}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPost(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{moment(item.created_at).format('MMMM Do YYYY')}</td>
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPost(item.id, item.title, item.content)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPost(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    searchPosts.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Ảnh tiêu đề</th>
                                                    <th>Tiêu đề</th>
                                                    <th>Tình trạng</th>
                                                    <th>Ngày tạo</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                searchPosts.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'  style={{ cursor: "pointer" }} onClick={()=>changeImageTitle(item.id, item.imageTitle)}>
                                                        <img src={`https://api.vtalentsolution.com/images/`+item.imageTitle+``} style={{height: '50px'}}/>
                                                    </td>
                                                    <td className='align-middle'>{item.title}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPost(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='align-middle'>{moment(item.created_at).format('MMMM Do YYYY')}</td>
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPost(item.id, item.title, item.content)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPost(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
        {/* Modal Create Industry */}
        <Modal show={show1} onHide={handleClose1} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Tạo bài viết mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input type="text" className="form-control" placeholder="Tiêu đề bài viết" onChange={(e) => setTitle(e.target.value)}/>
                <div className="col-12 mt-2">
                    <h6>Ảnh tiêu đề<sub></sub></h6>
                    <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                </div>
                <div className="col-12 mt-1">
                {
                    showImg !== '' &&
                    <img src={showImg} alt="" style={{ 'height':'120px', 'width' : 'auto'}}/>
                }
                </div>
                <div className="col-12 mt-4">
                    <label htmlFor="" className='mb-1'><b>Nội dung bài viết</b></label>
                    <ReactQuill
                        theme='snow'
                        multiple = {false}
                        onChange={setContent}
                        modules={{ 
                            toolbar : [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                
                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                [{ 'direction': 'rtl' }],                         // text direction
                                
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['link', 'image'],
                                
                                ['clean']                                         // remove formatting button
                            ]
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitCreatePost}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Post */}
        <Modal show={show2} onHide={handleClose2} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Update Bài Viết</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input value={title} type="text" className="form-control" onChange={(e) => setTitle(e.target.value)}/>
                <div className="col-12 mt-4">
                    <label htmlFor="" className='mb-1'><b>Nội dung bài viết</b></label>
                    <ReactQuill
                        value={content}
                        theme='snow'
                        multiple = {false}
                        onChange={setContent}
                        modules={{ 
                            toolbar : [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                
                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                [{ 'direction': 'rtl' }],                         // text direction
                                
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['link', 'image'],
                                
                                ['clean']                                         // remove formatting button
                            ]
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditPost}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Post */}
        <Modal show={show3} onHide={handleClose3} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Chọn ảnh tiêu đề</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="col-12 mt-2">
                    <h6>Ảnh tiêu đề<sub></sub></h6>
                    <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                </div>
                <div className="col-12 mt-1">
                {
                    showImg !== '' &&
                    <img src={showImg} alt="" style={{ 'height':'120px', 'width' : 'auto'}}/>
                }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitChangeImageTitle}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Blog
