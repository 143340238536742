import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import UserRole from "./pages/UserRole";
import User from "./pages/User";
import Job from "./pages/Job";
import Industry from "./pages/Industry";
import Cv from "./pages/Cv";
import DetailJob from "./pages/DetailJob";
import DetailCV from "./pages/DetailCV";
import Login from "./pages/Login";
import CompanyPartner from "./pages/CompanyPartner";
import Locations from "./pages/Locations";
import Blog from "./pages/Blog";
import DocumentTraining from "./pages/DocumentTraining";
import History from "./pages/History";
import Profile from "./pages/Profile";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                {
                    localStorage.getItem('role') == 4 &&
                    <Route path="/role" element={<UserRole/>} />
                }
                {
                    localStorage.getItem('role') == 4 &&
                    <Route path="/users" element={<User/>} />
                }
                    <Route path="/" element={<Login/>} />
                    <Route path="/login" element={<Login/>} />
                    <Route path="/home" element={<Home/>} />
                    <Route path="/list-job" element={<Job/>} />
                    <Route path="/industry" element={<Industry/>} />
                    <Route path="/company-partner" element={<CompanyPartner/>} />
                    <Route path="/list-cv" element={<Cv/>} />
                    <Route path="/locations" element={<Locations/>} />
                    <Route path="/blog" element={<Blog/>} />
                    <Route path="/detail-job/:id" element={<DetailJob/>} />
                    <Route path="/detail-cv/:id" element={<DetailCV/>} />
                    <Route path="/document-training" element={<DocumentTraining/>} />
                    <Route path="/history" element={<History/>} />
                    <Route path="/user-profile" element={<Profile/>} />
                </Routes>
            </BrowserRouter>
    </div>
    );
}

export default App;
