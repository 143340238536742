import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function Cv() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [userList, setUserList] = useState();
    const getUserList = async () => {
        axios.get(`https://api.vtalentsolution.com/api/getUsersOn`)
        .then((res)=>{
            setUserList(res.data);
        })
    }
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [userId, setUserId] = useState(0);
    const [statusId, setStatusId] = useState(0);
    const [number, setNumber]= useState([]);
    const [page, setPage] = useState(1);
    const [listCV, setListCV] = useState([]);
    const getListCV = () => {
        axios.get(`https://api.vtalentsolution.com/api/getCVsPage?page=`+page+``)
        .then((res) => {
            setListCV(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old);
        });
    }
    const selectCV = (i) =>{
        Swal.fire({
            title: "Bạn sẽ xử lý ứng viên này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("cvId", i);
                data.append("email", localStorage.getItem('email'));
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/selectCV",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã chọn thành công",
                        }).then(() => {
                            getListCV()
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [keyWord, setKeyWord] = useState('');
    const [searchCvs, setSearchCvs] = useState([]);
    const getSearchCVs = () => {
        axios(`https://api.vtalentsolution.com/api/getCvsSearch?keyWord=`+keyWord+`&userId=`+userId+`&statusId=`+statusId+``)
        .then((res)=> {
            const result = res.data;
            setSearchCvs(result);
        })
    }
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.openxmlformats-officedocument.wordprocessingml.docume'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const addCv = () => {
        setShow1(true);
    }
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [jobId, setJobId] = useState(0);
    const [recentJob, setRecentJob] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [note, setNote] = useState('');
    const formatPhoneNumber = /(0[3|5|7|8|9]+([0-9]{8}))\b/g;
    console.log();
    
    const submitAddCv = () => {
        if(
            fullName === '' && email === '' && phone === ''){
            Toast.fire({
                icon: 'error',
                title: 'Please enter information !!'
            });
        } else if(!phone.match(formatPhoneNumber)) {
            Toast.fire({
                icon: 'error',
                title: 'Your phone number format is incorrect !!'
            });
        // } else if (file === null) {
        //     Toast.fire({
        //         icon: 'error',
        //         title: 'Select your CV file, Please !!!'
        //     });
        } else {
            var data = new FormData();
            data.append('fullName', fullName);
            data.append('email', email);
            data.append('phone', phone);
            data.append('recentJob', recentJob);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('fileCV', file);
            data.append('note', note);
            data.append('userId', localStorage.getItem('id'));
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/addCv",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Thêm thành công'
                    }).then(()=>{
                        setShow1();
                        getListCV();
                        setFullName('');
                        setEmail('');
                        setPhone('');
                        setRecentJob('');
                        setLocationId(0);
                        setIndustryId(0);
                        setJobId(0);
                        setNote('');
                    })
                }else if(res.data.msg.fullName){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.fullName
                    });
                }else if(res.data.msg.email){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.email
                    });
                }else if(res.data.msg.phone){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.phone
                    });
                }else if(res.data.msg.locationId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.locationId
                    });
                }else if(res.data.msg.industryId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.industryId
                    });
                }else if(res.data.msg.userId){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg.userId
                    });
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                }
            })
        }
    }
    const removeUser = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn xóa nhân viên khỏi hồ sơ này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/removeUser?cvId=`+i+``)
                .then((res)=>{
                    const result = res.data;
                    if(result.check === true){
                        Toast.fire({
                            icon: 'success',
                            title: 'Xóa thành công'
                        }).then(()=>{
                            getListCV();
                        })
                    }else if (result.msg.cvId){
                        Toast.fire({
                            icon: 'error',
                            title: result.msg.cvId
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        }) 
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getListCV();
            getUserList();
            getCvStatus();
            getIndustryList();
            getLocationList();
        }else{
            window.location.replace('/');
        }  
    },[]);
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            axios.get(`https://api.vtalentsolution.com/api/getCVsPage?page=`+page+``)
            .then((res) => {
                setListCV(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }  
    },[page]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Danh sách CV</h5>
                                        <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={addCv}>
                                            <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                            Thêm
                                        </a>
                                    </div>
                                    <div className="row">
                                        <div className='col-lg-12 border rounded bg-light'>
                                            <div className="row justify-content-between">
                                                <h3 className='text-left py-3 col-md-6'>Tìm kiếm</h3>
                                                <a href="/history" className='text-right py-3 col-md-6 text-decoration-none'>Lịch sử xử lý hồ sơ</a>
                                            </div>
                                            
                                            <div className="row">
                                                <div className='col-lg-4 col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <input type="text" className="form-control form-control" placeholder="Tên Ứng Viên" onChange={(e)=>setKeyWord(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <select className='w-100 form-control border' onChange={(e)=>setUserId(e.target.value)}>
                                                                <option value="0" >Nhân viên xử hồ sơ</option>
                                                            {
                                                                userList && userList.length > 0 && userList.map((item, index)=>(
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                ))
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group">
                                                            <select className='w-100 form-control border' onChange={(e)=>setStatusId(e.target.value)}>
                                                                <option value="0" >Tình trạng hồ sơ</option>
                                                            {
                                                                cvStatus && cvStatus.length > 0 && cvStatus.map((item, index)=>(
                                                                    <option key={index} value={item.id}>{item.status_name}</option>
                                                                ))
                                                            }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-1 col-md-6 col-sm-12'>
                                                    <div className="mb-3">
                                                        <div className="input-group ">
                                                            {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                            <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>getSearchCVs()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                {
                                                    searchCvs.length>0 &&
                                                    <div className="col-12 mt-4">
                                                        <h6>Kết quả tìm kiếm: {searchCvs.length}</h6>
                                                    </div>
                                                }
                                                </div>
                                            </div>
                                        </div>           
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    listCV && listCV.length > 0 && searchCvs.length<=0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Tên Ứng Viên</th>
                                                    <th>Email</th>
                                                    
                                                    {/* <th>Lời nhắn</th> */}
                                                    <th>Người đang xử lý</th>
                                                    <th>Trạng thái hồ sơ</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listCV.map((item, index)=>(
                                                <tr key={index} className="text-center">
                                                    <td className='align-middle'>{item.fullname}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                    
                                                    {/* <td className='align-middle'>{item.message}</td> */}
                                                    {
                                                        item.accountName == null ?
                                                        <td className="align-middle">
                                                            {/* <span className='me-2 my-1 fs-4 rounded-circle border border-primary'><i className="fa-solid fa-hand-pointer text-primary" style={{ cursor:"pointer"}}></i></span> */}
                                                            <button className="btn btn-primary" onClick={()=>selectCV(item.id)}><i className="fa-solid fa-hand-pointer"></i></button>
                                                        </td>
                                                        :
                                                        <td className='align-middle'>{item.accountName}</td>
                                                    }
                                                    {/* <td className='align-middle' style={{ cursor: "pointer" }}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td> */}
                                                    {/* <td className='align-middle'>{item.status_name}</td> */}
                                                    {
                                                        item.status_name == 'Fisnish' ?
                                                        <td className='align-middle text-success'><b>{item.status_name}</b></td>
                                                        : item.status_name == 'Stop' ?
                                                        <td className='align-middle text-danger'><b>{item.status_name}</b></td>
                                                        : item.status_name == 'Not Start' ?
                                                        <td className='align-middle text-primary'><b>{item.status_name}</b></td>
                                                        :
                                                        <td className='align-middle text-warning'><b>{item.status_name}</b></td>
                                                    }
                                                    <td className='text-center align-middle'>
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                searchCvs.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Tên Ứng Viên</th>
                                                    <th>Email</th>
                                                    
                                                    {/* <th>Lời nhắn</th> */}
                                                    <th>Người đang xử lý</th>
                                                    <th>Trạng thái hồ sơ</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                searchCvs.map((item, index)=>(
                                                <tr key={index} className="text-center">
                                                    <td className='align-middle'>{item.fullname}</td>
                                                    <td className='align-middle'>{item.email}</td>
                                                   
                                                    {/* <td className='align-middle'>{item.message}</td> */}
                                                    {
                                                        item.accountName == null ?
                                                        <td className="align-middle">
                                                            {/* <span className='me-2 my-1 fs-4 rounded-circle border border-primary'><i className="fa-solid fa-hand-pointer text-primary" style={{ cursor:"pointer"}}></i></span> */}
                                                            <button className="btn btn-primary" onClick={()=>selectCV(item.id)}><i className="fa-solid fa-hand-pointer"></i></button>
                                                        </td>
                                                        :
                                                        <td className='align-middle'>{item.accountName}</td>
                                                    }
                                                    {/* <td className='align-middle' style={{ cursor: "pointer" }}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td> */}
                                                    {
                                                        item.status_name == 'Fisnish' ?
                                                        <td className='align-middle text-success'><b>{item.status_name}</b></td>
                                                        : item.status_name == 'Stop' ?
                                                        <td className='align-middle text-danger'><b>{item.status_name}</b></td>
                                                        : item.status_name == 'Not Start' ?
                                                        <td className='align-middle text-primary'><b>{item.status_name}</b></td>
                                                        :
                                                        <td className='align-middle text-warning'><b>{item.status_name}</b></td>
                                                    }
                                                    <td className='text-center align-middle'>
                                                        {
                                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            (localStorage.getItem('role') >=3) ?
                                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                            :
                                                            <span></span>
                                                        }
                                                        {
                                                            (item.user_id !== null && item.status_name === 'Stop' && localStorage.getItem('role') >= 3) ?
                                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                                            :
                                                            <span></span>
                                                        }
                                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square" style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                {
                                    searchCvs.length<=0 &&
                                    <div className="d-flex">
                                        <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                            <ul className="pagination">
                                            {
                                                number && number.length>0 && number.map((item,index)=>(
                                                    page == item ?
                                                        <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                    :
                                                        <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                ))
                                            }
                                            </ul>
                                        </nav>
                                    </div> 
                                }
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
        {/* Modal Add CV */}
        <Modal show={show1} onHide={handleClose1} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Thêm hồ sơ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="card-body">
                    <div className="row  align-items-center g-2">
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Họ tên ứng viên: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Họ tên ứng viên"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Email: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Số điện thoại: <span style={{color: "red"}}>*</span></b></label>
                            <input type="text" className='form-control mt-1' value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Số điện thoại"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Công việc gần đây:</b></label>
                            <input type="text" className='form-control mt-1' value={recentJob} onChange={(e) => setRecentJob(e.target.value)} placeholder="Công việc gần đây"/>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Địa điểm mong muốn: <span style={{color: "red"}}>*</span></b></label>
                            <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                                <option value='0'>Chọn địa điểm</option>
                            {
                                locationList && locationList.length > 0 && locationList.map((item, index) => (
                                    <option key={index} value={item.id}>{item.location}</option>
                                ))
                            } 
                            </select>
                        </div>
                        <div className="col-12 col-md-6 mt-4">
                            <label htmlFor="nameCourse"><b>Ngành nghề mong muốn: <span style={{color: "red"}}>*</span></b></label>
                            <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                                <option value='0'>Chọn ngành nghề</option>
                            {
                                industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                    <option key={index} value={item.id}>{item.industry}</option>
                                ))
                            }
                            </select>
                        </div>
                        
                        <div className="col-12 mt-4 mb-4">
                            <label htmlFor="" className='mb-1'><b>Ghi chú</b></label>
                            <ReactQuill
                                value={note}
                                theme='snow'
                                multiple = {false}
                                onChange={setNote}
                                modules={{ 
                                    toolbar : [
                                        ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                        ['blockquote', 'code-block'],
                                        
                                        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                        [{ 'direction': 'rtl' }],                         // text direction
                                        
                                        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                        
                                        [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                        [{ 'font': [] }],
                                        [{ 'align': [] }],
                                        ['link', 'image'],
                                        
                                        ['clean']                                         // remove formatting button
                                    ]
                                }}
                            />
                        </div>
                    </div>                    
                    
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={submitAddCv}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>
    </>
    )
}

export default Cv
