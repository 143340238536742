import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import 'react-quill/dist/quill.core.css';

function Job() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [locationList, setLocationList] = useState([]);
    const getLocationList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getLocationsOn`)
        .then((res)=> {
            setLocationList(res.data);
        })
    }
    const [industryList, setIndustryList] = useState([]);
    const getIndustryList = async () => {
        await axios(`https://api.vtalentsolution.com/api/getIndustriesOn`)
        .then((res)=> {
            setIndustryList(res.data)
        })
    }
    const [showAdd, setShowAdd] = useState(true);
    const [id, setId] = useState(0);
    const [showJobs, setShowJobs] = useState(true)
    const [jobs, setJobs] = useState([]);
    const [page, setPage] = useState(1);
    const [number, setNumber] = useState([])
    const getJobs = ()=> {
        axios.get(`https://api.vtalentsolution.com/api/getJobsPage?page=`+page+``)
        .then((res) => {
            setJobs(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old);
        });
    }
    const [jobName, setJobName] = useState('');
    const [salary, setSalary] = useState('');
    const [locationId, setLocationId] = useState(0);
    const [industryId, setIndustryId] = useState(0);
    const [jobDescription ,setJobDescription] = useState('');
    const [jobRequirements, setJobRequirements] = useState('');
    const [jobBenefits, setJobBenefits]= useState('');
    // const [requirementList, setRequirementList] = useState([{requirement: ""}]);
    // const addModuleRequirement = () => {
    //     setRequirementList([...requirementList, {requirement: ""}]);
    // }
    // const addRequirement = (value, index) => {
    //     const list = [...requirementList];
    //     list[index].requirement = value;
    // }
    // const delRequirement = (i) => {
    //     const list = [...requirementList];
    //     list.splice(i,1);
    //     setRequirementList(list);
    // }
    const createJob = () => {
        setShowJobs(false);
    }
    const cancelCreateJob = () => {
        setShowJobs(true);
        setJobName('');
        setSalary('');
        setLocationId(0);
        setLocation('');
        setIndustryId(0);
        setIndustryName('');
        setJobDescription('');
        setJobRequirements('');
        setJobBenefits('')
        // setRequirementList([{requirement: ""}]);
    }
    const submitCreateJob = async () => {
        // const requir = JSON.stringify(requirementList);
        if(jobName === '' && salary === '' && locationId === 0 && industryId === 0){
            Toast.fire({
                icon: "warning",
                title: "Vui lòng nhập đầy đủ dữ liệu",
            });
        } else {
            const data = new URLSearchParams();
            data.append('jobName', jobName);
            data.append('salary', salary);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            // data.append('requirementList', requir);
            data.append('jobRequirements', jobRequirements);
            data.append('jobDescription', jobDescription);
            data.append('jobBenefits', jobBenefits);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/createJob',
                data: data
            }).then((res)=> {
                console.log(res.data);
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Tạo thành công",
                    }).then(()=>{
                        getJobs();
                        setShowJobs(true);
                        setJobName('');
                        setSalary('');
                        setLocationId(0);
                        setIndustryId(0);
                        setJobDescription('');
                        setJobRequirements('');
                        setJobBenefits('');
                        // setRequirementList([{requirement: ""}]);
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.salary){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.salary,
                    });
                } else if (result.msg.locationId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.locationId,
                    });
                } else if (result.msg.industryId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.industryId,
                    });
                }
            })
        }
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false)
    const editJobName = (i, n) => {
        setShow1(true);
        setJobName(n);
        setId(i);
    }
    const [location, setLocation] = useState('');
    const [industryName, setIndustryName] = useState('');
    const editJob = async (i) => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJob?id=`+i+``)
        .then((res)=>{
            setId(i);
            setShowJobs(false);
            setShowAdd(false);
            setJobName(res.data[0].job_name);
            setSalary(res.data[0].salary);
            setLocationId(res.data[0].location_id);
            setLocation(res.data[0].location);
            setIndustryId(res.data[0].industry_id);
            setIndustryName(res.data[0].industryName);
            setJobDescription(res.data[0].job_description);
            setJobRequirements(res.data[0].job_requirements);
            setJobBenefits(res.data[0].job_benefits)
            
            // setRequirementList(JSON.parse(res.data[0].job_requirement_list));
        })
    }
    const submitEditJob = async () => {
        if(jobName === '' && salary === '' && locationId === 0 && industryId === 0){
            Toast.fire({
                icon: "warning",
                title: "Vui lòng nhập đầy đủ dữ liệu",
            });
        } else {
            const data = new URLSearchParams();
            data.append('id', id);
            data.append('jobName', jobName);
            data.append('salary', salary);
            data.append('locationId', locationId);
            data.append('industryId', industryId);
            data.append('jobRequirements', jobRequirements);
            data.append('jobDescription', jobDescription);
            data.append('jobBenefits', jobBenefits);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/editJob',
                data: data
            }).then((res)=> {
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Thành công",
                    }).then(()=>{
                        getJobs();
                        setId(0);
                        setShowJobs(true);
                        setShowAdd(true);
                        setJobName('');
                        setSalary('');
                        setLocationId(0);
                        setIndustryId(0);
                        setJobDescription('');
                        setJobRequirements('');
                        setJobBenefits('');
                        // setRequirementList([{requirement: ""}]);
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.salary){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.salary,
                    });
                } else if (result.msg.locationId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.locationId,
                    });
                } else if (result.msg.industryId){
                    Toast.fire({
                        icon: "error",  
                        title: result.msg.industryId,
                    });
                }
            })
        }
    }
    const submitEditJobName = async (e) => {
        e.preventDefault();
        if(jobName === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tên công việc",
            });
        } else {
            var data = new URLSearchParams();
            data.append('id', id);
            data.append('jobName', jobName);
            await axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/editJobName',
                data: data,
            }).then((res) => {
                const result = res.data;
                console.log(res);
                if (result.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Thành công",
                    }).then(()=>{
                        getJobs();
                        setJobName('');
                        setShow1(false);
                        // setId(0);
                    })
                } else if (result.msg.jobName){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.jobName,
                    });
                } else if (result.msg.id){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.id,
                    });
                } 
            })
        }
    }
    const switchJob = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đổi tình trạng công việc?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchJob?id=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(()=>{
                            getJobs();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const delJob = (i) => {
        console.log(i);
        Swal.fire({
            title: "Bạn có muốn xóa công việc này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteJob",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            getJobs();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [keyWord, setKeyWord] = useState('');
    const [jobsSearch, setJobsSearch] = useState([]);
    const searchJobs = () => {
        axios(`https://api.vtalentsolution.com/api/getJobsSearch?keyWord=`+keyWord+`&locationId=`+locationId+`&industryId=`+industryId+``)
        .then((res)=> {
            const result = res.data;
            setJobsSearch(result);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getJobs();
            getLocationList();
            getIndustryList();
        }else{
            window.location.replace('/');
        }  
    }, []);
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            axios.get(`https://api.vtalentsolution.com/api/getJobsPage?page=`+page+``)
            .then((res) => {
                setJobs(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }  
    }, [page]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Danh sách Công việc</h5>
                                        {
                                            showJobs ?
                                            <a href="#" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createJob}>
                                                <i className="fas fa-upload fa-sm text-white-50 me-2"></i> 
                                                Thêm
                                            </a>
                                            :
                                            <a href="#" className="d-sm-inline-block btn btn-sm btn-warning shadow-sm mt-2 p-2" onClick={cancelCreateJob}>
                                                <i className="fa-solid fa-ban fa-sm text-white-50 me-2"></i> 
                                                Hủy
                                            </a>
                                            
                                        } 
                                    </div>
                                    {
                                        showJobs &&
                                        <div className="row">
                                            <div className='col-lg-12 border rounded bg-light'>
                                                <h3 className='text-left py-3'>Tìm kiếm</h3>
                                                <div className="row">
                                                    <div className='col-lg-4 col-md-6 col-sm-12'>
                                                        <div className="mb-3">
                                                            <div className="input-group">
                                                                <input type="text" className="form-control form-control" placeholder="Keyword" onChange={(e)=>setKeyWord(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                        <div className="mb-3">
                                                            <div className="input-group">
                                                                <select className='w-100 form-control border' onChange={(e)=>setLocationId(e.target.value)}>
                                                                    <option value="0" >Select location</option>
                                                                {
                                                                    locationList && locationList.length > 0 && locationList.map((item, index)=>(
                                                                        <option key={index} value={item.id}>{item.location}</option>
                                                                    ))
                                                                }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-3 col-md-6 col-sm-12'>
                                                        <div className="mb-3">
                                                            <div className="input-group">
                                                                <select className='w-100 form-control border' onChange={(e)=>setIndustryId(e.target.value)}>
                                                                    <option value="0">Industry</option>
                                                                {
                                                                    industryList && industryList.length > 0 && industryList.map((item, index)=>(
                                                                        <option key={index} value={item.id}>{item.industry}</option>
                                                                    ))
                                                                }
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-lg-1 col-md-6 col-sm-12'>
                                                        <div className="mb-3">
                                                            <div className="input-group ">
                                                                {/* <a className="btn btn-primary btn-lg py-2 px-4 ml-auto d-lg-block" href="">Search</a> */}
                                                                <button className="btn btn-primary ml-auto w-100 d-lg-block" onClick={()=>searchJobs()}><i className="fa-solid fa-magnifying-glass"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-12'>
                                                    {
                                                        jobsSearch.length>0 &&
                                                        <div className="col-12 mt-4">
                                                            <h6>Kết quả tìm kiếm: {jobsSearch.length}</h6>
                                                        </div>
                                                    }
                                                    </div>
                                                </div>
                                            </div>           
                                        </div>
                                    }
                                    
                                </div>
                                <div className="card-body">
                                {
                                    showJobs && jobs && jobs.length > 0 && jobsSearch.length <=0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th className='align-middle'>Công việc</th>
                                                    <th className='align-middle'>Lương</th>
                                                    <th className='align-middle'>Địa điểm</th>
                                                    <th className='align-middle'>Ngành nghề</th>
                                                    <th className='align-middle'>Tình trạng</th>
                                                    <th className='align-middle'>Số lượng Cv Apply</th>
                                                    {/* <th>Ngày tạo</th> */}
                                                    <th className='align-middle'>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                jobs.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <th className='align-middle' style={{ cursor: "pointer" }} onClick={()=>editJobName(item.id, item.job_name)}>{item.job_name}</th>
                                                    <td className='align-middle'>{item.salary}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchJob(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    {/* <td className='align-middle'>{item.job_requirements}</td> */}
                                                    <td className='align-middle text-center'><b>{item.SL}</b></td>
                                                    <td className='text-center align-middle'>
                                                        {/* <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editJob(item.id, item.salary, item.location, item.industry, item.requirements)}  style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        <a href={"/detail-job/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square secondary" onClick={()=>editJob(item.id)} style={{ cursor:"pointer"}}></i></span>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash danger" onClick={()=>delJob(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    showJobs && jobsSearch.length >0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th className='align-middle'>Công việc</th>
                                                    <th className='align-middle'>Lương</th>
                                                    <th className='align-middle'>Địa điểm</th>
                                                    <th className='align-middle'>Ngành nghề</th>
                                                    <th className='align-middle'>Tình trạng</th>
                                                    <th className='align-middle'>Số lượng Cv Apply</th>
                                                    {/* <th>Ngày tạo</th> */}
                                                    <th className='align-middle'>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                jobsSearch.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <th className='align-middle' style={{ cursor: "pointer" }} onClick={()=>editJobName(item.id, item.job_name)}>{item.job_name}</th>
                                                    <td className='align-middle'>{item.salary}</td>
                                                    <td className='align-middle'>{item.location}</td>
                                                    <td className='align-middle'>{item.industryName}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchJob(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    {/* <td className='align-middle'>{item.job_requirements}</td> */}
                                                    <td className='align-middle text-center'><b>{item.SL}</b></td>
                                                    <td className='text-center align-middle'>
                                                        {/* <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editJob(item.id, item.salary, item.location, item.industry, item.requirements)}  style={{ cursor:"pointer", color: "grey" }}></i></span> */}
                                                        <a href={"/detail-job/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-pen-to-square secondary" onClick={()=>editJob(item.id)} style={{ cursor:"pointer"}}></i></span>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash danger" onClick={()=>delJob(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                {
                                    showJobs && jobsSearch.length <=0 &&
                                     <div className="d-flex">
                                        <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                            <ul className="pagination">
                                            {
                                                number && number.length>0 && number.map((item,index)=>(
                                                    page == item ?
                                                        <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                    :
                                                        <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                            <span className="page-link">{item}</span>
                                                        </li>
                                                ))
                                            }
                                            </ul>
                                        </nav>
                                    </div> 
                                }
                               {
                                !showJobs &&
                                    <div className="card-body">
                                        {/* <h3 className="text-center mb-5"><b>Tạo công việc</b></h3> */}
                                        <div className="row  align-items-center g-2">
                                            <div className="col-12 col-md-6">
                                                <label htmlFor="nameCourse"><b>Tên công việc <span style={{color: "red"}}>*</span></b></label>
                                                <input type="text" className='form-control mt-1' value={jobName} onChange={(e) => setJobName(e.target.value)} placeholder="Nhập tên công việc"/>
                                            </div>
                                            <div className="col-12 col-md-6 mt-4">
                                                <label htmlFor="nameCourse"><b>Lương công việc <span style={{color: "red"}}>*</span></b></label>
                                                <input type="text" className='form-control mt-1' value={salary} onChange={(e) => setSalary(e.target.value)} placeholder="Nhập mức lương"/>
                                            </div>
                                            <div className="col-12 col-md-6 mt-4">
                                                <label htmlFor="nameCourse"><b>Chọn địa điểm công việc <span style={{color: "red"}}>*</span></b></label>
                                                <select name="" id="" className="form-control mt-1" onChange={(e)=> setLocationId(e.target.value)}>
                                                    <option value={locationId}>{location}</option>
                                                {
                                                    locationList && locationList.length > 0 && locationList.map((item, index) => (
                                                        <option key={index} value={item.id}>{item.location}</option>
                                                    ))
                                                } 
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-6 mt-4">
                                                <label htmlFor="nameCourse"><b>Chọn ngành nghề <span style={{color: "red"}}>*</span></b></label>
                                                <select name="" id="" className="form-control mt-1" onChange={(e)=> setIndustryId(e.target.value)}>
                                                    <option value={industryId}>{industryName}</option>
                                                {
                                                    industryList && industryList.length > 0 && industryList.map((item, index)=> (
                                                        <option key={index} value={item.id}>{item.industry}</option>
                                                    ))
                                                }
                                                </select>
                                            </div>
                                            <div className="col-12 mt-4">
                                                <label htmlFor="" className='mb-1'><b>Mô tả công việc</b></label>
                                                <ReactQuill
                                                    value={jobDescription}
                                                    theme='snow'
                                                    multiple = {false}
                                                    onChange={setJobDescription}
                                                    modules={{ 
                                                        toolbar : [
                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                            ['blockquote', 'code-block'],
                                                            
                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                                            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                                            [{ 'direction': 'rtl' }],                         // text direction
                                                            
                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            
                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                            [{ 'font': [] }],
                                                            [{ 'align': [] }],
                                                            ['link', 'image'],
                                                            
                                                            ['clean']                                         // remove formatting button
                                                        ]
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 mt-4 mb-4">
                                                <label htmlFor="" className='mb-1'><b>Yêu cầu công việc</b></label>
                                                <ReactQuill
                                                    value={jobRequirements}
                                                    theme='snow'
                                                    multiple = {false}
                                                    onChange={setJobRequirements}
                                                    modules={{ 
                                                        toolbar : [
                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                            ['blockquote', 'code-block'],
                                                            
                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                                            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                                            [{ 'direction': 'rtl' }],                         // text direction
                                                            
                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            
                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                            [{ 'font': [] }],
                                                            [{ 'align': [] }],
                                                            ['link', 'image'],
                                                            
                                                            ['clean']                                         // remove formatting button
                                                        ]
                                                    }}
                                                />
                                            </div>
                                            <div className="col-12 mt-4 mb-4">
                                                <label htmlFor="" className='mb-1'><b>Quyền lợi</b></label>
                                                <ReactQuill
                                                    value={jobBenefits}
                                                    theme='snow'
                                                    multiple = {false}
                                                    onChange={setJobBenefits}
                                                    modules={{ 
                                                        toolbar : [
                                                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                                            ['blockquote', 'code-block'],
                                                            
                                                            [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                                            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                                            [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                                            [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                                            [{ 'direction': 'rtl' }],                         // text direction
                                                            
                                                            [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                                            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                                            
                                                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                                            [{ 'font': [] }],
                                                            [{ 'align': [] }],
                                                            ['link', 'image'],
                                                            
                                                            ['clean']                                         // remove formatting button
                                                        ]
                                                    }}
                                                />
                                            </div>
                                            {/* {
                                                requirementList && requirementList.map((item, index) => (
                                                    <div className="col-10 col-md-8 my-2 me-2" key={index}>
                                                        <label htmlFor="nameCourse"><b>Yêu cầu {index + 1}</b></label>
                                                        <div className="d-flex flex-row">
                                                            <input type="text" className='form-control mt-1'  onChange={(e) => addRequirement(e.target.value, index)} placeholder="Yêu cầu công việc"/>
                                                            <button className="btn btn-danger ms-2" onClick={()=>delRequirement(index)}><i className="fa-solid fa-trash"></i></button>
                                                        </div>
                                                    </div>
                                                ))
                                            } */}
                                        </div>
                                        {/* <button className="btn btn-primary mt-2 me-2" onClick={addModuleRequirement}>Thêm Module</button> */}
                                        <div className="d-flex justify-content-center my-4">
                                        {
                                            !showAdd ?
                                            <button className="btn btn-primary mt-2 me-2 px-4" onClick={submitEditJob}>Lưu</button>
                                            :
                                            <button className="btn btn-primary mt-2 me-2 px-4" onClick={submitCreateJob}>Lưu</button>
                                        }
                                            <button className="btn btn-warning mt-2 me-2 px-4" onClick={cancelCreateJob}>Hủy</button>
                                        </div>
                                       
                                    </div>
                               }
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>

        {/* Modal Edit Job Name */}
        <Modal show={show1} onHide={handleClose1}>
            <Modal.Header closeButton>
                <Modal.Title>Chỉnh sửa tên công việc</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={jobName}
                    type="text"
                    className="form-control"
                    onChange={(e) => setJobName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditJobName}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default Job
