import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import SpinnerS from '../components/SpinnerS';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import moment from 'moment';
import { Spinner } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { set } from "date-fns";

function DetailCV() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const {id} = useParams();
    const [cv, setCv] = useState({});
    const getSingleCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleCv?id=`+id+``)
        .then((res)=>{
            setCv(res.data[0]);
        })
    }
    const [cvStatus, setCvStatus] = useState([]);
    const getCvStatus = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCvStatus?id=`)
        .then((res)=>{
            setCvStatus(res.data);
        })
    }
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [status, setStatus] = useState(0);
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const changeStatus = () => {
        setStatus(cv.status)
        setShow1(true);
    }
    const submitChangeStatus = async () => {
        const data = new FormData();
        data.append('id', id);
        data.append('statusId', status);
        await axios({
            method: 'post',
            url: 'https://api.vtalentsolution.com/api/changeStatus',
            data: data,
        }).then((res)=> {
            const result = res.data;
            if(result.check === true){
                Toast.fire({
                    icon: "success",
                    title: "Đổi trạng thái thành công",
                }).then(()=>{
                    getSingleCv();
                    setShow1(false);
                })
            } else if (result.msg.id){
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            } else if (result.msg.statusId){
                Toast.fire({
                    icon: "error",
                    title: result.msg.statusId,
                });
            }
        })
    }
    const saveNewFile = () => {
        if(file === null){
            Toast.fire({
                icon: "error",
                title: "Hãy thêm file CV",
            })
        }else{
            const data = new FormData();
            data.append('id',id);
            data.append('newFileCv',file);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/addNewFileCv",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã thêm thành công",
                    }).then(()=>{
                        getSingleCv();
                    })
                } else if (result.msg){
                    Toast.fire({
                        icon: "error",
                        title: result.msg,
                    });
                } else if (result.msg.id){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.id,
                    });
                }
            })
        }
    }
    const [cvList, setCvList] = useState([]);
    const getCvListWithUser = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getCVsWithUser?userId=`+localStorage.getItem('id')+``)
        .then((res)=>{
            setCvList(res.data);
        })
    }
    const [note, setNote] = useState('');
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const showModalNote = (e)=>{
        setShow2(true);
        setNote(cv.note);
    } 
    const submitAddNote = () => { 
        // console.log(note);
        // console.log(id);  
        if(note === undefined){
            Toast.fire({
                icon: "warning",
                title: "Nhập nội dung",
            })
        } else {
            const data = new URLSearchParams();
            data.append('cvId', id);
            data.append('note', note);
            axios({
                method: 'post',
                url: 'https://api.vtalentsolution.com/api/addNote',
                data: data
            })
            // axios.post(`https://api.vtalentsolution.com/api/addNote?cvId=`+id+`&note=`+note+``)
            .then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã thêm ghi chú thành công",
                    }).then(()=>{
                        getSingleCv();
                        setShow2(false);
                    })
                } else if (result.msg.cvId){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.cvId,
                    });
                } else if (result.msg.note){
                    Toast.fire({
                        icon: "error",
                        title: result.msg.note,
                    });
                }
            })
        }
    }
    const [jobsSumCv, setJobsSumCv] = useState([]);
    const getJobsSumCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getJobsAndCV`)
        .then((res)=>{
            setJobsSumCv(res.data);
        })
    }
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const openListJob = () => {
        setShow3(true);
    }
    const applyFor = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn apply Cv vào Job này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/applyFor?cv_id=`+id+`&job_id=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã apply hồ sơ thành công",
                        }).then(()=>{
                            setShow3(false);
                        })
                    } else if (result.msg.cv_id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.cv_id,
                        });
                    }else if (result.msg.job_id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.job_id,
                        });
                    }else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [listJobId, setListJobId] = useState([]);
    const getJobIdWitCvId = () => {
        axios.get(`https://api.vtalentsolution.com/api/getListJobWithCv?cvId=`+id+``)
        .then((res)=>{
            setListJobId(res.data);
        })
    }
    const [jobsSearch, setJobsSearch] = useState([]);
    const getJobsSearch = async (e) => {
        await axios.get(`https://api.vtalentsolution.com/api/getJobsWithKeyword?keyWord=`+e+``)
        .then((res)=>{
            setJobsSearch(res.data);
        })
    }
    const [singleJobCv, setSingleJobCv] = useState({});
    const getSingleJobCv = async () => {
        await axios.get(`https://api.vtalentsolution.com/api/getSingleJobCv?cv_id=`+id+``)
        .then((res)=>{
            setSingleJobCv(res.data);
        })
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getSingleCv();
            getSingleJobCv();
            getCvStatus();
            getCvListWithUser();
            getJobsSumCv();
            getJobIdWitCvId();
            getJobsSearch();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
        <>
            <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
                <div id="wrapper">
                    <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                    {/* <!-- Content Wrapper --> */}
                    <div id="content-wrapper" className="d-flex flex-column">
                        {/* <!-- Main Content --> */}
                        <div id="content">
                            <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-xl-8 col-lg-7">
                                        <div className="card shadow mb-4">
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Thông tin CV</h6>
                                                <span className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={()=>openListJob()}>
                                                    <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                                    Apply For
                                                </span>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-user text-dark me-1"></i><b>Họ và Tên Ứng Viên:</b></p>
                                                        <p className="text-secondary fw-bold">{cv.fullname}</p>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-envelope text-dark me-1"></i><b>Email:</b></p>
                                                        <p className="text-secondary fw-bold">{cv.email}</p>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-phone text-dark me-1"></i><b>Số điện thoại:</b></p> 
                                                        <p className="text-secondary fw-bold">{cv.phone}</p>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-circle-info text-dark me-1"></i><b>Công việc gần đây:</b> </p>
                                                        <span className="text-secondary fw-bold">{cv.recent_job}</span>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-location-dot text-dark me-1"></i><b>Nơi làm việc mong muốn:</b></p>
                                                        <p className="text-secondary fw-bold">{cv.location}</p>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-industry text-dark me-1"></i><b className="me-3">Ngành nghề mong muốn:</b></p>
                                                        <p className="text-secondary fw-bold">{cv.industryName}</p>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p><i className="fa-solid fa-message text-dark me-1"></i>
                                                            <b className="me-3">Lời nhắn:</b>
                                                        </p>
                                                        <p className="text-secondary fw-bold">{cv.message}</p>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p>
                                                            <i className="fa-solid fa-message text-dark me-1"></i>
                                                            <b className="me-3">Ghi chú:</b>
                                                            <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square text-primary" onClick={()=>showModalNote(cv.note)}  style={{ cursor:"pointer"}}></i></span>
                                                        </p>
                                                        <p className="text-secondary fw-bold" dangerouslySetInnerHTML={{ __html: cv.note }}></p>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p>
                                                            <i className="fa-solid fa-file text-dark me-1"></i><b className="me-3">File CV:</b>
                                                            <a href={`https://api.vtalentsolution.com/FileCV/`+cv.file_cv+``} download={cv.file_cv}  target="_blank" className='mx-3 my-1 fs-4 primary'>
                                                                <i className="fa-solid fa-download"></i>
                                                            </a>

                                                        </p>
                                                        <p className="text-secondary fw-bold">{cv.file_cv}</p>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p>
                                                            <i className="fa-solid fa-paperclip text-dark me-1"></i><b className="me-3">New File CV:</b>
                                                        {
                                                            cv.new_file_cv === null ?
                                                            ''
                                                            :
                                                            <a href={`https://api.vtalentsolution.com/FileCV/`+cv.new_file_cv+``} target="_blank" download={cv.new_file_cv} className='mx-3 my-1 fs-4 primary'>
                                                                <i className="fa-solid fa-download"></i>
                                                            </a>
                                                            // download={cv.new_file_cv}
                                                        }
                                                        </p>
                                                        <p className="text-secondary fw-bold">{cv.new_file_cv}</p>
                                                        <div className="mt-3">
                                                            <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false} className="mt-5"/>
                                                            {
                                                                showFile !== '' &&
                                                                // <h5>{file.name}</h5>
                                                                <div>
                                                                    <b className="me-3">{file.name}</b>
                                                                    <a className='mx-3 my-1 fs-4' onClick={()=>saveNewFile()} style={{ cursor:"pointer"}}><i className="fa-solid fa-floppy-disk"></i></a>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p><i className="fa-solid fa-circle-info text-dark me-1"></i><b>Công việc đang Apply:</b> </p>
                                                        <div className="text-secondary fw-bold">
                                                            <p>Vị trí: {singleJobCv.JobName}</p>
                                                            <p>Lương: {singleJobCv.Salary}</p> 
                                                            <p>Ngành nghề: {singleJobCv.industryName}</p> 
                                                            <p>Địa điểm: {singleJobCv.location}</p> 
                                                        </div>
                                                    </div>
                                                    <div className="col-12 my-2">
                                                        <p>
                                                            <i className="fa-solid fa-flag text-dark me-1"></i>
                                                            <b className="me-3">Tình trạng hồ sơ:</b> 
                                                            <a className='mx-3 my-1 fs-4' onClick={()=>changeStatus(cv.status)} style={{ cursor:"pointer"}}><i className="fa-solid fa-pen-to-square"></i></a>
                                                        </p>
                                                    {
                                                        cv.status_name == 'Fisnish' ?
                                                        <p className='align-middle text-success'><b>{cv.status_name}</b></p>
                                                        : cv.status_name == 'Stop' ?
                                                        <p className='align-middle text-danger'><b>{cv.status_name}</b></p>
                                                        : cv.status_name == 'Not Start' ?
                                                        <p className='align-middle text-primary'><b>{cv.status_name}</b></p>
                                                        :
                                                        <p className='align-middle text-warning'><b>{cv.status_name}</b></p>
                                                    }
                                                        {/* <p className="text-secondary fw-bold">{cv.status_name}</p> */}
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-solid fa-calendar-days text-dark me-1"></i><b className="me-3">Ngày tạo hồ sơ:</b></p>
                                                        <p className="text-secondary fw-bold">{moment(cv.created_at).format('MMMM Do YYYY, h:mm:ss A')}</p>
                                                    </div>
                                                    <div className="col-6 my-2">
                                                        <p><i className="fa-regular fa-calendar-days text-dark me-1"></i><b className="me-3">Ngày tạo hồ sơ:</b></p>
                                                        <p className="text-secondary fw-bold">{moment(cv.updated_at).format('MMMM Do YYYY, h:mm:ss A')}</p>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-xl-4 col-lg-5">
                                        <div className="card shadow mb-4">
                                            {/* <!-- Card Header - Dropdown --> */}
                                            <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                                <h6 className="m-0 font-weight-bold text-primary">Các CV bạn đang xử lý</h6>
                                            </div>
                                            <div className="card-body">
                                                <div className="border rounded" style={{maxHeight: "1000px", overflowY: "auto"}}>
                                                {
                                                    cvList && cvList.length > 0 && cvList.map((item, index)=>(
                                                    <a href={"/detail-cv/"+item.id} className="p-4 mb-2 bg-secondary-subtle border rounded text-decoration-none d-block"  key={index}>
                                                        {/* <a href={"/detail-job/"+item.id} className="text-decoration-none"><h5>{item.job_name}</h5></a> */}
                                                        <div className="mt-1">
                                                            <h6 className="m-0 mt-2"><i className="fa-regular fa-user mr-2"></i>{item.fullname}</h6>
                                                            <h6 className="m-0 mt-2"><i className="fa-solid fa-phone mr-2"></i>{item.phone}</h6>
                                                            <h6 className="m-0 mt-2"><i className="fa-regular fa-envelope mr-2"></i>{item.email}</h6>
                                                            <h6 className="m-0 mt-2"><i className="fa-solid fa-spinner mr-2"></i>{item.status_name}</h6>
                                                        </div>
                                                    </a>
                                                    ))
                                                }
                                                {
                                                    !cvList || cvList.length <= 0 &&
                                                    <div>
                                                        {
                                                            <SpinnerS/>
                                                        }
                                                    </div>
                                                }
                                                </div>
                                                {/* <div className="mt-4 text-center small">
                                                    <span className="mr-2">
                                                        <i className="fas fa-circle text-primary"></i> Direct
                                                    </span>
                                                    <span className="mr-2">
                                                        <i className="fas fa-circle text-success"></i> Social
                                                    </span>
                                                    <span className="mr-2">
                                                        <i className="fas fa-circle text-info"></i> Referral
                                                    </span>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                    </div>
                </div>
            </div>

            {/* Change Status Model */}
            <Modal show={show1} onHide={handleClose1}>
                <Modal.Header closeButton>
                    <Modal.Title>Thay đổi tính trạng</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                        <label htmlFor="status"><b>Chọn tình trạng hồ sơ <span style={{color: "red"}}>*</span></b></label>
                        <select name="" id="status" className="form-control mt-1" onChange={(e)=> setStatus(e.target.value)}>
                            <option value={cv.status}>{cv.status_name}</option>
                        {
                            cvStatus && cvStatus.length > 0 && cvStatus.map((item, index)=> (
                                <option key={index} value={item.id}>{item.status_name}</option>
                            ))
                        }
                        </select>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose1}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={submitChangeStatus}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Add Note */}
            <Modal show={show2} onHide={handleClose2} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Ghi chú của nhân viên</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div className="col-12 mt-4 mb-4">
                    {/* <label htmlFor="" className='mb-1'><b>Ghi chú</b></label> */}
                    <ReactQuill
                        value={note}
                        theme='snow'
                        multiple = {false}
                        onChange={setNote}
                        modules={{ 
                            toolbar : [
                                ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                                ['blockquote', 'code-block'],
                                
                                [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                                [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                                [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                                [{ 'direction': 'rtl' }],                         // text direction
                                
                                [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                                
                                [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                                [{ 'font': [] }],
                                [{ 'align': [] }],
                                ['link', 'image'],
                                
                                ['clean']                                         // remove formatting button
                            ]
                        }}
                    />
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                        Đóng
                    </Button>
                    <Button variant="primary" onClick={submitAddNote}>
                        Lưu
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Modal Add Document */}
            <Modal show={show3} onHide={handleClose3} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Apply For Job</Modal.Title>
                    
                </Modal.Header>
                <Modal.Body>
                <div className="mb-4">
                    <input type="text" className="form-control w-50" placeholder="Từ khóa" onKeyUp={(e)=>getJobsSearch(e.target.value)}/>
                </div>
                <div className="card-body">
                {
                    jobsSumCv && jobsSumCv.length > 0 && jobsSearch.length <= 0 &&
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr className='table-secondary text-center'>
                                    <th className='align-middle'>Công Việc</th>
                                    <th className='align-middle'>Địa điểm</th>
                                    <th className='align-middle'>Ngành nghề</th>
                                    <th className='align-middle'>Lương</th>
                                    <th className='align-middle'>Số lượng CV Apply</th>
                                    <th className='align-middle'>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                jobsSumCv.map((item, index)=>(
                                <tr key={index} className="text-center">
                                    <td className='align-middle'>{item.job_name}</td>
                                    <td className='align-middle'>{item.location}</td>
                                    <td className='align-middle'>{item.industryName}</td>
                                    <td className='align-middle'>{item.salary}</td>
                                    <td className='align-middle'>{item.SL}</td>
                                    <td className='text-center align-middle'>
                                        {/* {
                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                            :
                                            (localStorage.getItem('role') >=3) ?
                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                            :
                                            <span></span>
                                        }
                                        {
                                            (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                            :
                                            <span></span>
                                        } */}
                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                        {
                                            listJobId.includes(item.id) ?
                                            // <button className='btn btn-secondary btn-sm disabled'>Đã Apply</button>
                                            <span className='mx-1 my-1'>Đã Apply</span>
                                            :
                                            <button className='btn btn-primary' onClick={()=>applyFor(item.id)}>Apply</button>
                                        }
                                    </td>
                                </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                }               
                {
                    jobsSearch && jobsSearch.length > 0 &&
                    <div className="table-responsive">
                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                            <thead>
                                <tr className='table-secondary text-center'>
                                    <th className='align-middle'>Công Việc</th>
                                    <th className='align-middle'>Địa điểm</th>
                                    <th className='align-middle'>Ngành nghề</th>
                                    <th className='align-middle'>Lương</th>
                                    <th className='align-middle'>Số lượng CV Apply</th>
                                    <th className='align-middle'>Tùy chỉnh</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                jobsSearch.map((item, index)=>(
                                <tr key={index} className="text-center">
                                    <td className='align-middle'>{item.job_name}</td>
                                    <td className='align-middle'>{item.location}</td>
                                    <td className='align-middle'>{item.industryName}</td>
                                    <td className='align-middle'>{item.salary}</td>
                                    <td className='align-middle'>{item.SL}</td>
                                    <td className='text-center align-middle'>
                                        {/* {
                                            (item.user_id !== null && item.user_id == localStorage.getItem('id')) ?
                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                            :
                                            (localStorage.getItem('role') >=3) ?
                                            <a href={"/detail-cv/"+item.id} className='mx-3 my-1 fs-4 primary'><i className="fa-solid fa-eye" style={{ cursor:"pointer"}}></i></a>
                                            :
                                            <span></span>
                                        }
                                        {
                                            (item.user_id !== null && item.status_name == 'Stop' && localStorage.getItem('role') >= 3) ?
                                            <span className='mx-3 my-1 fs-4' onClick={()=>removeUser(item.id)}><i className="fa-solid fa-stop text-danger" style={{ cursor:"pointer"}}></i></span>
                                            :
                                            <span></span>
                                        } */}
                                        {/* <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash"  style={{ cursor:"pointer", color: "red" }}></i></span> */}
                                        {
                                            listJobId.includes(item.id) ?
                                            // <button className='btn btn-secondary btn-sm disabled'>Đã Apply</button>
                                            <span className='mx-1 my-1'>Đã Apply</span>
                                            :
                                            <button className='btn btn-primary' onClick={()=>applyFor(item.id)}>Apply</button>
                                        }
                                    </td>
                                </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                } 
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose3}>
                        Đóng
                    </Button>
                    {/* <Button variant="primary" onClick={submitApplyFor}>
                        Lưu
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default DetailCV
