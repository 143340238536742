import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FileUploader } from "react-drag-drop-files";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function DocumentTraining() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [listDoc, setListDoc] = useState([]);
    const getDocs = async () => {
        await axios.get('https://api.vtalentsolution.com/api/getDocs')
        .then((res)=>{
            setListDoc(res.data);
        })
    }
    const [file, setFile] = useState(null);
    const [showFile, setShowFile] = useState('');
    const fileTypes = [
        'doc', 'docx', 'pdf', 'vnd.openxmlformats-officedocument.wordprocessingml.document', 'vnd.openxmlformats-officedocument.wordprocessingml.docume'
    ];
    const uploadFile = (file) => {
        setFile(file);
        var url = URL.createObjectURL(file);
        setShowFile(url);
    }
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createDoc = () => {
        setShow1(true);
    }
    const submitCreateDoc = () => {
        if (file === null) {
            Toast.fire({
                icon: 'error',
                title: 'Select your file, Please !!!'
            });
        } else {
            var data = new FormData();            
            data.append('fileDoc', file);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createDoc",
                data: data, 
            }).then((res)=>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: 'success',
                        title: 'Thêm tài liệu thành công!'
                    }).then(()=>{
                        getDocs();
                        setShow1(false);
                    })
                }else if(res.data.msg){
                    Toast.fire({
                        icon: 'error',
                        title: res.data.msg
                    });
                }
            })
        }
    }
    const switchStatus = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn ẩn tài liệu này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchDoc?docId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(()=>{
                            getDocs();
                        })
                    } else if (result.msg.docId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.docId,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const deleteDoc = (i) => {
        Swal.fire({
            title: "Bạn có muốn xóa tài liệu này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("docId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deleteDoc",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            getDocs();
                        });
                    } else if (result.msg.docId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.docId,
                        });
                    } 
                });
            } else if (result.isDenied) {
            }
        });
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getDocs();
        }else{
            window.location.replace('/');
        }  
    },[]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Tài Liệu Training</h5>
                                        <a href="#" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createDoc}>
                                            <i className="fas fa-upload fa-sm text-white-50"></i> 
                                            Thêm
                                        </a>
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    listDoc && listDoc.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary text-center'>
                                                    <th>File Name</th>
                                                    <th>Tình trạng</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                listDoc.map((item, index)=>(
                                                <tr key={index} className="text-center">
                                                    <td className='align-middle'>{item.file_name}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchStatus(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='mx-3 my-1 fs-4'><i className="fa-solid fa-trash" style={{ cursor:"pointer", color: "red" }} onClick={()=>deleteDoc(item.id)}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>

        {/* Modal Add Document */}
        <Modal show={show1} onHide={handleClose1} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Thêm tài liệu Training</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="card-body">
                <div className="row  align-items-center g-2">
                    {/* <div className="col-12 col-md-6 mt-4">
                        <label htmlFor="nameCourse"><b>Họ tên ứng viên: <span style={{color: "red"}}>*</span></b></label>
                        <input type="text" className='form-control mt-1' value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Họ tên ứng viên"/>
                    </div> */}
                    <div className="control-group my-4">
                        <label htmlFor=""><b style={{ color: "black" }}>Tài liệu Training <span style={{color: "red"}}>*</span></b></label>
                        <FileUploader handleChange={uploadFile} name="file" types={fileTypes} multiple={false}/>
                    </div>
                    <div className="control-group mb-2">
                        {
                            showFile !== '' &&
                            // <img src={showFile} alt=" " style={{ 'height':'120px', 'width' : 'auto'}}/>
                            <h5>{file.name}</h5>
                        }
                    </div>
                </div>                 
                
            </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitCreateDoc}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default DocumentTraining
