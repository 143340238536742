import React from 'react';
import Swal from "sweetalert2";

function Sidebar({ toggle, onToggle }) {
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const checkLogout = (e) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đăng xuất khỏi tài khoản?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                if(localStorage.getItem('email') && localStorage.getItem('token')){
                    localStorage.removeItem('email');
                    localStorage.removeItem('name');
                    localStorage.removeItem('token');
                    localStorage.removeItem('id');
                    localStorage.removeItem('role');
                    window.location.replace('/');
                }else{
                    window.location.replace('/');
                }  
            } else if (result.isDenied) {
            }
        })  
    }
    return (
    <>
        <ul className={toggle ? "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" : "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion toggled" } id="accordionSidebar">

            {/* <!-- Sidebar - Brand --> */}
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/home">
                {/* <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div> */}
                {/* <div className="sidebar-brand-text mx-1">V Talent Solution</div> */}
                <img  className="img-fluid rounded  mx-auto d-block mb-lg-0" src="../img/vtalent2.png" alt="Logo" style={{  width: "50%" }}/>

                
            </a>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider my-0"/>

            {/* <!-- Nav Item - Dashboard --> */}
            <li className="nav-item active">
                <a className="nav-link" href="/home">
                    {/* <i className="fas fa-fw fa-tachometer-alt"></i> */}
                    <span>V Talent Solution</span>
                </a>
            </li>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider"/>

            {/* <!-- Heading --> */}
            {/* <div className="sidebar-heading">
                Interface
            </div> */}

            {/* <!-- Nav Item - Pages Collapse Menu --> */}
            {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-cog"></i>
                    <span>Components</span>
                </a>
                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Components:</h6>
                        <a className="collapse-item" href="buttons.html">Buttons</a>
                        <a className="collapse-item" href="cards.html">Cards</a>
                    </div>
                </div>
            </li> */}

            {/* <!-- Nav Item - Utilities Collapse Menu --> */}
            {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-wrench"></i>
                    <span>Utilities</span>
                </a>
                <div id="collapseUtilities" className="collapse" aria-labelledby="headingUtilities"
                    data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Custom Utilities:</h6>
                        <a className="collapse-item" href="utilities-color.html">Colors</a>
                        <a className="collapse-item" href="utilities-border.html">Borders</a>
                        <a className="collapse-item" href="utilities-animation.html">Animations</a>
                        <a className="collapse-item" href="utilities-other.html">Other</a>
                    </div>
                </div>
            </li> */}

            {/* <!-- Divider --> */}
            {/* <hr className="sidebar-divider"/> */}

            {/* <!-- Heading --> */}
            <div className="sidebar-heading">
                Dashboard
            </div>

            {/* <!-- Nav Item - Pages Collapse Menu --> */}
            {/* <li className="nav-item">
                <a className="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsePages"
                    aria-expanded="true" aria-controls="collapsePages">
                    <i className="fas fa-fw fa-folder"></i>
                    <span>Pages</span>
                </a>
                <div id="collapsePages" className="collapse" aria-labelledby="headingPages" data-parent="#accordionSidebar">
                    <div className="bg-white py-2 collapse-inner rounded">
                        <h6 className="collapse-header">Login Screens:</h6>
                        <a className="collapse-item" href="login.html">Login</a>
                        <a className="collapse-item" href="register.html">Register</a>
                        <a className="collapse-item" href="forgot-password.html">Forgot Password</a>
                        <div className="collapse-divider"></div>
                        <h6 className="collapse-header">Other Pages:</h6>
                        <a className="collapse-item" href="404.html">404 Page</a>
                        <a className="collapse-item" href="blank.html">Blank Page</a>
                    </div>
                </div>
            </li> */}

            {/* <!-- Nav Item - Charts --> */}
            <li className="nav-item">
                <a className="nav-link" href="/home">
                    <i className="fa-solid fa-house"></i>
                    <span>Home</span></a>
            </li>
            {
                localStorage.getItem('role') == 4 &&
                <li className="nav-item">
                    <a className="nav-link" href="/role">
                        <i className="fas fa-solid fa-user-secret"></i>
                        <span>Loại tài khoản</span></a>
                </li>
            }
            {
                localStorage.getItem('role') == 4 &&
                <li className="nav-item">
                    <a className="nav-link" href="/users">
                        <i className="fas fa-regular fa-user"></i>
                        <span>Tài khoản</span></a>
                </li>
            }

            <li className="nav-item">
                <a className="nav-link" href="/list-cv">
                    <i className="fa-regular fa-file"></i>
                    <span>CV</span>
                </a>
            </li>

            <li className="nav-item">
                <a className="nav-link" href="/list-job">
                    <i className="fa-solid fa-briefcase"></i>
                    <span>Jobs</span></a>
            </li>

            <li className="nav-item">
                <a className="nav-link" href="/industry">
                    <i className="fa-solid fa-industry"></i>
                    <span>Industries</span></a>
            </li>

            <li className="nav-item">
                <a className="nav-link" href="/locations">
                    <i className="fa-solid fa-location-crosshairs"></i>
                    <span>Locations</span>
                </a>
            </li>

            <li className="nav-item">
                <a className="nav-link" href="/company-partner">
                    <i className="fa-solid fa-handshake"></i>
                    <span>Partners</span>
                </a>
            </li>


            <li className="nav-item">
                <a className="nav-link" href="/blog">
                    <i className="fa-solid fa-blog"></i>
                    <span>Posts</span></a>
            </li>

            {
                localStorage.getItem('role') >= 3 &&
                <li className="nav-item">
                    <a className="nav-link" href="/document-training">
                        <i className="fa-solid fa-blog"></i>
                        <span>Training</span></a>
                </li>
            }

            <li className="nav-item">
                <a className="nav-link" href="/history">
                    <i className="fa-solid fa-clock-rotate-left"></i>
                    <span>History</span></a>
            </li>

            <li className="nav-item">
                <a className="nav-link" onClick={()=>checkLogout()}>
                    <i className="fa-regular fa-file"></i>
                    <span>Logout</span></a>
            </li>

            {/* <!-- Divider --> */}
            <hr className="sidebar-divider d-none d-md-block"/>

            {/* <!-- Sidebar Toggler (Sidebar) --> */}
            <div className="text-center d-none d-md-inline">
                <button className="rounded-circle border-0" id="sidebarToggle" onClick={()=> onToggle(!toggle)}></button>
            </div>

        </ul>
    </>
    )
}

export default Sidebar
