import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Spinner from "../components/Spinner";


function User() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [role, setRole] = useState([]);   
    const [id, setId] = useState(0);
    const getRole = () => {
        fetch("https://api.vtalentsolution.com/api/getRoles")
        .then((res) => res.json())
        .then((res) => {
            setRole(res);
        });
    };
    const [show5, setShow5] = useState(false);

    const [number, setNumber]= useState([]);
    const [page, setPage] = useState(1);
    const [users, setUsers] = useState([]);
    const getUsers = () => {
        axios.get(`https://api.vtalentsolution.com/api/getUser?page=`+page+``)
        .then((res) => {
            setUsers(res.data.data);
            const old = [];
            for (let i = 1; i <= res.data.last_page; i++) {
                old.push(i);
            }
            setNumber(old)
        });
    }
    const [userName, setUserName] = useState("");
    const [email, setEmail] = useState("");
    const [roleUser, setRoleUser] = useState("");

    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const showModalCreateUser = (e) => {
        setShow2(true);
    };
    const submitCreateUser = async (e) => {
        e.preventDefault();
        if (userName === "" || email === "" || roleUser == 0) {
            Toast.fire({
                icon: "warning",
                title: "Nhập đầy đủ thông tin",
            });
        } else {
            setShow5(true)
            var data = new URLSearchParams();
            data.append("name", userName);
            data.append("email", email);
            data.append("idRole", roleUser);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createUser",
                data: data,
            }).then((res) => {
                setShow5(false);
                if (res.data.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Đã thêm thành công",
                    }).then(() => {
                        setShow2(false);
                        getUsers();
                    });
                } else if (res.data.msg.name) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.name,
                    });
                } else if (res.data.msg.email) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.email,
                    });
                } else if (res.data.msg.idRole) {
                    setShow5(false);
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.idRole,
                    });
                }
            });
        }
    };
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const showModalEditUser = (id, userNameOld) => {
        setShow3(true);
        setId(id);
        setUserName(userNameOld);
    };
    const submitEditUser = async (e) => {
        e.preventDefault();
        if (userName === "") {
            Toast.fire({
                icon: "warning",
                title: "Nhập tên tài khoản",
            });
        } else {
            var data = new URLSearchParams();
            data.append("id", id);
            data.append("name", userName);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editUserName",
                data: data,
            }).then((res) => {
                if (res.data.check === true) {
                    Toast.fire({
                        icon: "success",
                        title: "Đã sửa thành công",
                    }).then(() => {
                        setShow3(false);
                        getUsers();
                    });
                } else if (res.data.msg.name) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.name,
                    });
                } else if (res.data.msg.id) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.id,
                    });
                }
            });
        }
    };
    const switchUser = (id) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đổi tình trạng của tài khoản này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("id", id);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/switchUser",
                    data: data, 
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(() => {
                            getUsers();
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    };

    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const showModalEditRoleUser = (id) => {
        setShow4(true);
        setId(id);
    };
    const submitEditRoleUser = async (e) => {
        e.preventDefault();
        var data = new URLSearchParams();
        data.append("id", id);
        data.append('idRole', roleUser)
        axios({
            method: "post",
            url: "https://api.vtalentsolution.com/api/editRoleUser",
            data: data,
        }).then((res) => {
            const result = res.data;
            if (result.check === true) {
                Toast.fire({
                    icon: "success",
                    title: "Đã đổi thành công",
                }).then(() => {
                    getUsers();
                    setShow4(false);
                });
            } else if (result.msg.id    ) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.id,
                });
            }else if (result.msg.idRole) {
                Toast.fire({
                    icon: "error",
                    title: result.msg.idRole,
                });
            }
        });
    }
    const deleteUser = (e) =>{
        Swal.fire({
            title: "Bạn có muốn xóa tài khoản này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                const data = new FormData();
                data.append('id', e);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/delUser",
                    data: data,
                }).then((res) => {
                    // console.log(res.data);
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            if(res.data.result.last_page >= page){
                                getUsers();
                            }else{
                                setPage(res.data.result.last_page-1);
                                getUsers();
                            }
                        });
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }

    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getRole();
        }else{
            window.location.replace('/');
        }
    }, []);
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token') && localStorage.getItem('role') == 4){
            axios.get(`https://api.vtalentsolution.com/api/getUser?page=`+page+``)
            .then((res) => {
                setUsers(res.data.data);
                const old = [];
                for (let i = 1; i <= res.data.last_page; i++) {
                    old.push(i);
                }
                setNumber(old);
            });
        }else{
            window.location.replace('/');
        }
    }, [page]);
    return (
    <>
        {
            show5 &&
            <Spinner/>
        }
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                    <h5 className="m-0 font-weight-bold text-primary">Tài khoản người dùng</h5>
                                        <a href="#" className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={showModalCreateUser}>
                                            <i className="fas fa-upload fa-sm text-white-50"></i> 
                                            Thêm tài khoản
                                        </a>
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    users && users.length > 0 && 
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Tên</th>
                                                    <th>Email</th>
                                                    <th>Quyền</th>
                                                    <th>Tình trạng</th>
                                                    <th>Ngày tạo</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                users.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <th className='align-middle' style={{ cursor: "pointer" }}  onClick={() => showModalEditUser(item.id, item.name)}>{item.name}</th>
                                                    <td className='align-middle'>{item.email}</td>
                                                    <th className='align-middle' style={{ cursor: "pointer" }} onClick={()=>showModalEditRoleUser(item.id)}>
                                                        {item.rolename}
                                                    </th>
                                                    <td className='align-middle' onClick={() => switchUser(item.id)}  style={{ cursor: "pointer" }}>
                                                        {
                                                            item.status === 1 ? 
                                                                <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                            : 
                                                                <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                        }
                                                    </td>
                                                    <td className='align-middle'>{item.created_at}</td>
                                                    <td className='text-center align-middle'>
                                                        {/* <button className="btn btn-danger" onClick={()=>deleteUser(item.id)}>Xóa</button> */}
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={() => deleteUser(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                <div className="d-flex">
                                    <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                        <ul className="pagination">
                                        {
                                            number && number.length>0 && number.map((item,index)=>(
                                                page == item ?
                                                    <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                                :
                                                    <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                            ))
                                        }
                                        </ul>
                                    </nav>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
                    
        {/* Modal Create User */}
        <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
                <Modal.Title>Tạo Tài Khoản</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    type="text"
                    id="username"
                    className="form-control"
                    placeholder="Tên tài khoản"
                    onChange={(e) => setUserName(e.target.value)}
                />
                <input
                    type="email"
                    id="email"
                    className="form-control mt-3"
                    placeholder="Email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <div className="mt-3">
                    <label htmlFor="role" className="me-3">
                    <h6>Chọn loại tài khoản:</h6>
                    </label>
                    <select
                        name="role"
                        id="role"
                        className="form-control"
                        onChange={(e) => setRoleUser(e.target.value)}
                    >
                        <option value="0">Loại Tài Khoản</option>
                    {
                        role && role.length > 0 && role.map((item, index) => (
                        <option key={item.id} value={item.id}>
                            {item.name}
                        </option>
                    ))};
                    </select>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitCreateUser}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal Edit User */}
        <Modal show={show3} onHide={handleClose3}>
            <Modal.Header closeButton>
                <Modal.Title>Update Tên Tài Khoản</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <input
                    value={userName}
                    type="text"
                    id="editRole"
                    className="form-control"
                    placeholder="Tên Loại tài khoản"
                    onChange={(e) => setUserName(e.target.value)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose3}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditUser}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
        {/* Modal Edit User Role */}
        <Modal show={show4} onHide={handleClose4}>
            <Modal.Header closeButton>
                <Modal.Title>Update quyền hạn Tài Khoản</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="role" className="me-3">
                    <h6>Quyền hạn của tài khoản:</h6>
                </label>
                <select name="role" id="editRole" className="form-control" onChange={(e) => setRoleUser(e.target.value)}>
                    <option value="0">Chọn quyền hạn</option>
                {
                    role && role.length > 0 && role.map((item, index) => (
                    <option key={item.id} value={item.id}>
                        {item.name}
                    </option>
                ))};
                </select>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose4}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditRoleUser}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default User
