import React, { useEffect, useState } from 'react';
import Swal from "sweetalert2";
import axios from 'axios';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Spinner from "../components/Spinner";

function Login() {
    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }                                    
    });
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const checkLogin = () => {
        if(email === '' || password === ''){
            Toast.fire({
                icon: "error",
                title: "Nhập đầy đủ thông tin đăng nhập"
            });
        }else{
            // var data = new FormData();
            // data.append('email', email);
            // data.append('password', password);
            axios(
                `https://api.vtalentsolution.com/api/checkLogin?email=`+email+`&password=`+password+``
            ).then((res) => {
                if(res.data.check == true){
                    Toast.fire({
                        icon: "success",
                        title: "Đăng nhập thành công"
                    }).then(()=>{
                        localStorage.setItem('email',email);
                        localStorage.setItem('id',res.data.result[0].id);
                        localStorage.setItem('role',res.data.result[0].role_id);
                        localStorage.setItem('name', res.data.result[0].name);
                        localStorage.setItem('token',res.data.result[0].remember_token);
                        window.location.replace('/home');
                    })
                }else if (res.data.msg.email) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.email,
                    });
                }else if (res.data.msg.password) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.password,
                    });
                }else if (res.data.msg) {
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg,
                    });
                }
            });
        }
    }
    const [show5, setShow5] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const resetPassword = () => setShow(true);
    const submitResetPassword = (e) => {
        e.preventDefault();
        if(email === ''){
            Toast.fire({
                icon: "error",
                title: "Nhập đầy đủ thông tin đăng nhập"
            });
        }else{
            setShow5(true);
            axios.post(`https://api.vtalentsolution.com/api/resetPassword?email=`+email+``)
            .then((res) => {
                if(res.data.check === true){
                    setShow5(false);
                    Toast.fire({
                        icon: "success",
                        title: "Vui lòng kiểm tra email để lấy mật khẩu mới !"
                    }).then(()=>{
                        setShow(false);
                    })
                }else if (res.data.msg.email) {
                    setShow5(false);
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg.email,
                    })
                }else if (res.data.msg) {
                    setShow5(false);
                    Toast.fire({
                      icon: "error",
                      title: res.data.msg,
                    })
                }
            })
        }
    }
    useEffect(()=> {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            window.location.replace('/home');
        }
    },[]);
    return (
    <>
    {
        show5 &&
        <Spinner/>
    }
    <div className="bg-gray-200">
        <main className="main-content  mt-0">
            <div className="page-header align-items-start min-vh-100" style={{backgroundImage: "url('https://images.unsplash.com/photo-1497294815431-9365093b7331?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80')"}}>
                <span className="mask bg-gradient-dark opacity-6"></span>
                <div className="container min-vh-100">
                    <div className="row min-vh-100">
                        <div className="col-lg-5 col-md-8 col-12 m-auto">
                            <div className="card z-index-0 fadeIn3 fadeInBottom">
                                <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 border border-primary rounded">
                                    <div className="bg-gradient-primary shadow-primary py-3 pe-1">
                                    <h4 className="text-white font-weight-bolder text-center mt-2 mb-0">Sign in</h4>
                                    <div className="row mt-3">
                                        <h5 className="text-white font-weight-bolder text-center mt-2 mb-0">Work With V Talent</h5>
                                    </div>
                                     {/* <div className="row mt-3">
                                        <div className="col-2 text-center ms-auto">
                                        <a className="btn btn-link px-3" href="javascript:;">
                                            <i className="fa fa-facebook text-white text-lg"></i>
                                        </a>
                                        </div>
                                        <div className="col-2 text-center px-1">
                                        <a className="btn btn-link px-3" href="javascript:;">
                                            <i className="fa fa-github text-white text-lg"></i>
                                        </a>
                                        </div>
                                        <div className="col-2 text-center me-auto">
                                        <a className="btn btn-link px-3" href="javascript:;">
                                            <i className="fa fa-google text-white text-lg"></i>
                                        </a>
                                        </div>
                                    </div> */}
                                    </div>
                                </div>
                                <div className="card-body my-4">
                                    
                                    <div className="input-group input-group-outline my-4">
                                        <input type="email" className="form-control" placeholder='Email' onChange={(e) => setEmail(e.target.value)}/>
                                    </div>
                                    <div className="input-group input-group-outline mb-4">
                                        <input type="password" className="form-control" placeholder='Password' onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                    {/* <div className="form-check form-switch d-flex align-items-center mb-4">
                                        <input className="form-check-input" type="checkbox" id="rememberMe"/>
                                        <label className="form-check-label mb-0 ms-3" htmlFor="rememberMe">Remember me</label>
                                    </div> */}
                                    <div className="text-center">
                                        <button type="button" className="btn bg-gradient-primary w-100 my-5 mb-2 text-white" onClick={()=>checkLogin()}>Sign in</button>
                                    </div>
                                    <p className="mt-4 text-sm text-center">
                                        <a className="text-primary text-gradient font-weight-bold" onClick={resetPassword} style={{ cursor: "pointer" }}>Quên mật khẩu?</a>
                                    </p>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <footer className="footer position-absolute bottom-2 py-2 w-100">
                    <div className="container">
                        <div className="row align-items-center justify-content-lg-between">
                            <div className="col-12 my-auto mx-auto">
                                <div className="copyright text-center text-sm text-white text-lg-start">
                                    © <a href="" className="font-weight-bold text-white mx-2" target="_blank">V Talent Solution</a>
                                      for a better web.
                                </div>
                            </div>
                        </div>
                    </div>
                </footer> */}
            </div>
        </main>
    </div>
    {/* Modal Forgot Password */}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>PASSWORD RESET</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-8 text-center mt-2">
                    Enter your email address and we'll 
                    send you an email with instructions 
                    to reset your password
                </div>
                <div className="col-12 col-md-8 text-center mt-4">
                    <input
                        type="email"
                        placeholder='Email'
                        className="form-control text-center"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>
                <div className="col-12 col-md-8 text-center mt-4">
                    <button className='btn btn-primary w-100' onClick={submitResetPassword}>Reset Password</button>
                </div>
            </div>
            
        </Modal.Body>
    </Modal>
    </>
    )
}

export default Login
