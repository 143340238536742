import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

function CompanyPartner() {
    const [toggleSidebar, setToggleSidebar] = useState(true);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 1500,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });
    const [id, setId] = useState(0);
    // const [number, setNumber]= useState([]);
    // const [page, setPage] = useState(1);
    const [partners, setPartners] = useState([]);
    const getPartners = () => {
        axios.get(`https://api.vtalentsolution.com/api/getPartners`)
        .then((res) => {
            setPartners(res.data);
            // const old = [];
            // for (let i = 1; i <= res.data.last_page; i++) {
            //     old.push(i);
            // }
            // setNumber(old);
        });
    }
    const switchPartner = (i) => {
        Swal.fire({
            icon:'question',
            text: "Bạn muốn đổi tình trạng công ty đối tác?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không`,
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(`https://api.vtalentsolution.com/api/switchPartner?partnerId=`+i+``)
                .then((res)=>{
                    if(res.data.check === true){
                        Toast.fire({
                            icon: "success",
                            title: "Đã đổi thành công",
                        }).then(()=>{
                            getPartners();
                        })
                    } else if (result.msg.id) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.id,
                        });
                    }
                })
            } else if (result.isDenied) {
            }
        })
    }
    const [companyPartner, setCompanyPartner] = useState('');
    const [mst, setMST] = useState('');
    const [descriptionPartner, setDescriptionPartner] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [contractStatus, setContractStatus] = useState('');
    const formatPhoneNumber = /(0[2|3|5|7|8|9]+([0-9]{8}))\b/g;
    const [show1, setShow1] = useState(false);
    const handleClose1 = () => setShow1(false);
    const createPartner = () => {
        setShow1(true);
    }
    const submitCreatePartner = () => {
        if(companyPartner === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tên công ty đối tác",
            });
        } else if(phone != '' && !phone.match(formatPhoneNumber)){
            Toast.fire({
                icon: 'error',
                title: 'Số điện thoại không đúng định dạng !!'
            })
        }else {
            var data = new URLSearchParams();
            data.append('companyPartner', companyPartner);
            data.append('mst', mst);
            data.append('description', descriptionPartner);
            data.append('address', address);
            data.append('phone', phone);
            data.append('contractStatus', contractStatus);
            axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/createPartner",
                data: data,
            })
            .then((res) =>{
                if(res.data.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã thêm thành công",
                    }).then(() => {
                        setShow1(false);
                        getPartners();
                        setContractStatus('');
                    });
                } else if (res.data.msg.companyPartner) {
                    Toast.fire({
                        icon: "error",
                        title: res.data.msg.companyPartner,
                    });
                }
            })
        }
    }
    const [show2, setShow2] = useState(false);
    const handleClose2 = () => setShow2(false);
    const editPartner = (i, a, b, c, d, e, f) => {
        setShow2(true);
        setId(i);
        setCompanyPartner(a);
        setMST(b);
        setDescriptionPartner(c);
        setAddress(d);
        setPhone(e);
        setContractStatus(f);
    }
    const submitEditPartner = async () => {
        if(companyPartner === ''){
            Toast.fire({
                icon: "warning",
                title: "Nhập tên công ty đối tác",
            });
        } else if(phone != '' && !phone.match(formatPhoneNumber)){
            Toast.fire({
                icon: 'error',
                title: 'Số điện thoại không đúng định dạng !!'
            })
        }else {
            var data = new FormData();
            data.append('partnerId', id);
            data.append('companyPartner', companyPartner);
            data.append('mst', mst);
            data.append('description', descriptionPartner);
            data.append('address', address);
            data.append('phone', phone);
            data.append('contractStatus', contractStatus);
            await axios({
                method: "post",
                url: "https://api.vtalentsolution.com/api/editPartner",
                data: data,
            }).then((res)=>{
                const result = res.data;
                if(result.check === true){
                    Toast.fire({
                        icon: "success",
                        title: "Đã sửa thành công",
                    }).then(() => {
                        setShow2(false);
                        getPartners();
                        setId(0);
                        setCompanyPartner('');
                        setMST('');
                        setDescriptionPartner('');
                        setAddress('');
                        setPhone('');
                        setContractStatus('');
                    });
                } else if (result.msg.partnerId) {
                    Toast.fire({
                        icon: "error",
                        title: result.msg.partnerId,
                    });
                } 
            })
        }
    }
    const delPartner = async (i) => {
        Swal.fire({
            title: "Bạn có muốn xóa công ty đối tác này?",
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Có",
            denyButtonText: `Không xóa`,
        }).then((result) => {
            if (result.isConfirmed) {
                var data = new URLSearchParams();
                data.append("partnerId", i);
                axios({
                    method: "post",
                    url: "https://api.vtalentsolution.com/api/deletePartner",
                    data: data,
                }).then((res) => {
                    const result = res.data;
                    if (result.check === true) {
                        Toast.fire({
                            icon: "success",
                            title: "Đã xóa thành công",
                        }).then(() => {
                            getPartners();
                        });
                    } else if (result.msg.partnerId) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg.partnerId,
                        });
                    } else if (result.msg) {
                        Toast.fire({
                            icon: "error",
                            title: result.msg,
                        });
                    }
                });
            } else if (result.isDenied) {
            }
        });
    }
    const [searchPartners, setSearchPartners] = useState([]);
    const getSearchPartners = (e) => { 
        axios.get(`https://api.vtalentsolution.com/api/getSearchPartners?search=`+e+``)
        .then((res)=>{
            setSearchPartners(res.data);
        })
    }
    useEffect(() => {
        if(localStorage.getItem('email') && localStorage.getItem('token')){
            getPartners()
        }else{
            window.location.replace('/');
        }  
    }, []);
    // useEffect(() => {
    //     if(localStorage.getItem('email') && localStorage.getItem('token')){
    //         axios.get(`https://api.vtalentsolution.com/api/getPartners5?page=`+page+``)
    //         .then((res) => {
    //             setIndustries(res.data.data);
    //             const old = [];
    //             for (let i = 1; i <= res.data.last_page; i++) {
    //                 old.push(i);
    //             }
    //             setNumber(old);
    //         });
    //     }else{
    //         window.location.replace('/');
    //     }  
    // }, [page]);
    return (
    <>
        <div id="page-top" className={toggleSidebar ? "" : "sidebar-toggled"}>
            <div id="wrapper">
                <Sidebar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                {/* <!-- Content Wrapper --> */}
                <div id="content-wrapper" className="d-flex flex-column">
                    {/* <!-- Main Content --> */}
                    <div id="content">
                        <Navbar onToggle={setToggleSidebar} toggle={toggleSidebar}/>
                        <div className="container-fluid">
                            <div className="card shadow mb-4">
                                <div className="card-header py-3">
                                    <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                        <h5 className="m-0 font-weight-bold text-primary">Danh sách công ty đối tác</h5>
                                        <a className="d-sm-inline-block btn btn-sm btn-primary shadow-sm mt-2 p-2" onClick={createPartner}>
                                            <i className="fas fa-upload fa-sm text-white-50 mr-2"></i> 
                                            Thêm
                                        </a>
                                    </div>
                                    <div className="row mb-4">
                                        {/* <h5 className="m-0 font-weight-bold text-primary">Tìm kiếm</h5> */}
                                        <div className="col-lg-6 col-12">
                                            <input type="text" className="form-control border small" placeholder="Search for..." 
                                            aria-label="Search" aria-describedby="basic-addon2" onKeyUp={(e)=>getSearchPartners(e.target.value)}/>
                                        </div>
                                        {
                                            searchPartners.length>0 &&
                                            <div className="col-12 mt-4">
                                                <h6>Kết quả tìm kiếm: {searchPartners.length}</h6>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="card-body">
                                {
                                    partners && partners.length > 0 && searchPartners.length <=0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Công ty</th>
                                                    <th>MST</th>
                                                    <th>Mô tả</th>
                                                    <th>Địa chỉ</th>
                                                    <th>Số điện thoại</th>
                                                    <th>Hợp đồng</th>
                                                    <th>Tình trạng</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                partners.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'>{item.company_name}</td>
                                                    <td className='align-middle'>{item.mst}</td>
                                                    <td className='align-middle'>{item.description}</td>
                                                    <td className='align-middle'>{item.address}</td>
                                                    <td className='align-middle'>{item.phone}</td>
                                                    <td className='align-middle'>{item.contract_status}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPartner(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPartner(item.id, item.company_name, item.mst, item.description, item.address, item.phone, item.contract_status)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPartner(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                {
                                    searchPartners.length > 0 &&
                                    <div className="table-responsive">
                                        <table className="table table-bordered" id="dataTable" width="100%" cellSpacing="0">
                                            <thead>
                                                <tr className='table-secondary'>
                                                    <th>Công ty</th>
                                                    <th>MST</th>
                                                    <th>Mô tả</th>
                                                    <th>Địa chỉ</th>
                                                    <th>Số điện thoại</th>
                                                    <th>Hợp đồng</th>
                                                    <th>Tình trạng</th>
                                                    <th>Tùy chỉnh</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                searchPartners.map((item, index)=>(
                                                <tr key={index} className="">
                                                    <td className='align-middle'>{item.company_name}</td>
                                                    <td className='align-middle'>{item.mst}</td>
                                                    <td className='align-middle'>{item.description}</td>
                                                    <td className='align-middle'>{item.address}</td>
                                                    <td className='align-middle'>{item.phone}</td>
                                                    <td className='align-middle'>{item.contract_status}</td>
                                                    <td className='align-middle' style={{ cursor: "pointer" }} onClick={()=>switchPartner(item.id)}>
                                                    {
                                                        item.status === 1 ? 
                                                            <b style={{ color: "green" }}><i className="fa-solid fa-toggle-on fs-4"></i></b>
                                                        : 
                                                            <b style={{ color: "red" }}><i className="fa-solid fa-toggle-off fs-4"></i></b>
                                                    }
                                                    </td>
                                                    <td className='text-center align-middle'>
                                                        <span className='me-2 my-1 fs-4'><i className="fa-solid fa-pen-to-square" onClick={()=>editPartner(item.id, item.company_name, item.mst, item.description, item.address, item.phone)}  style={{ cursor:"pointer", color: "grey" }}></i></span>
                                                        <span className='my-1 fs-4'><i className="fa-solid fa-trash" onClick={()=>delPartner(item.id)} style={{ cursor:"pointer", color: "red" }}></i></span>
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                </div>
                                {/* <div className="d-flex">
                                    <nav aria-label="Page navigation example" style={{ margin: 'auto' }}>
                                        <ul className="pagination">
                                        {
                                            number && number.length>0 && number.map((item,index)=>(
                                                page == item ?
                                                    <li className="page-item mx-1 active" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                                :
                                                    <li className="page-item mx-1" key={index} style={{ cursor: "pointer" }} onClick={()=>setPage(item)}>
                                                        <span className="page-link">{item}</span>
                                                    </li>
                                            ))
                                        }
                                        </ul>
                                    </nav>
                                </div>  */}
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </div>
        </div>
        {/* Modal Create Partner */}
        <Modal show={show1} onHide={handleClose1} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Đối tác mới</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-12">
                        <input type="text" className="form-control" placeholder="Tên công ty đối tác" onChange={(e) => setCompanyPartner(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Mã số thuế" onChange={(e) => setMST(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Địa chỉ văn phòng" onChange={(e) => setAddress(e.target.value)}/>
                    </div>
                    <div className="col-12 mt-3">
                        <input type="text" className="form-control" placeholder="Mô tả: Các ngành nghề của công ty" onChange={(e) => setDescriptionPartner(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <input type="text" className="form-control" placeholder="Số điện thoại" onChange={(e) => setPhone(e.target.value)}/>
                    </div>
                    <div className="col-6 mt-3">
                        <select name="" id="" className="form-control" onChange={(e) => setContractStatus(e.target.value)}>
                            <option value={contractStatus}>{contractStatus}</option>
                            <option value="Not signed yet">Not signed yet</option>
                            <option value="Signed">Signed</option>
                            <option value="Cancel Contract">Cancel Contract</option>
                        </select>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose1}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitCreatePartner}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>

        {/* Modal Edit Partner */}
        <Modal show={show2} onHide={handleClose2} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Update Thông tin đối tác</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <div className="row">
                <div className="col-12">
                    <input type="text" className="form-control" placeholder="Tên công ty đối tác" value={companyPartner} onChange={(e) => setCompanyPartner(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Mã số thuế" value={mst} onChange={(e) => setMST(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Địa chỉ văn phòng" value={address} onChange={(e) => setAddress(e.target.value)}/>
                </div>
                <div className="col-12 mt-3">
                    <input type="text" className="form-control" placeholder="Mô tả công ty" value={descriptionPartner} onChange={(e) => setDescriptionPartner(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    <input type="text" className="form-control" placeholder="Số điện thoại" value={phone} onChange={(e) => setPhone(e.target.value)}/>
                </div>
                <div className="col-6 mt-3">
                    {/* <input type="text" className="form-control" placeholder="Số điện thoại" value={phone} onChange={(e) => setPhone(e.target.value)}/> */}
                    <select name="" id="" className="form-control" onChange={(e) => setContractStatus(e.target.value)}>
                        <option value={contractStatus}>{contractStatus}</option>
                        <option value="Not signed yet">Not signed yet</option>
                        <option value="Signed">Signed</option>
                        <option value="Cancel Contract">Cancel Contract</option>
                    </select>
                </div>
            </div>
                
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose2}>
                    Đóng
                </Button>
                <Button variant="primary" onClick={submitEditPartner}>
                    Lưu
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    )
}

export default CompanyPartner
